<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('PRICELISTS.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('PRICELISTS.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddPricelist">{{$t('PRICELISTS.ADD')}}</vs-button>
              <AddPricelistDlg ref="addPricelistDlg" v-on:Success="LoadData()"></AddPricelistDlg>
            </div> 
            <div id="pricelists_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="pricelists"
                :noDataText="$t('PRICELISTS.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat
                v-model="selectedPricelist">

                <template slot="header">
                  <h3>
                    {{$t('PRICELISTS.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('PRICELISTS.TABLE.COL1')}}
                  </vs-th>                 
                  <vs-th sort-key="currency_fk">
                    {{$t('PRICELISTS.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="country_fk">
                    {{$t('PRICELISTS.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="note">
                    {{$t('PRICELISTS.TABLE.COL5')}}
                  </vs-th> 
                  <vs-th sort-key="valid_from">
                    {{$t('PRICELISTS.TABLE.COL6')}}
                  </vs-th>  
                  <vs-th sort-key="valid_to">
                    {{$t('PRICELISTS.TABLE.COL7')}}
                  </vs-th>                                        
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr  :data="tr" :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>                         
                    <vs-td :data="tr.currency_fk">
                      {{GetNameOfCurrency(tr.currency_fk)}}
                    </vs-td>
                    <vs-td :data="tr.country_fk">
                      {{GetNameOfCountry(tr.country_fk)}}
                    </vs-td>
                    <vs-td :data="tr.note">
                      {{tr.note}}
                    </vs-td> 
                    <vs-td :data="tr.valid_from">
                      {{DateToString(tr.valid_from,false)}}
                    </vs-td>   
                    <vs-td :data="tr.valid_to">
                      {{DateToString(tr.valid_to,false)}}
                    </vs-td>                                   
                    <vs-td>
                      <vs-button  @click="PrintPricelist(tr.id)" size="small" color="primary" type="filled" icon="print"></vs-button>
                      <vs-button  @click="EditPricelist(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>
                      <vs-button  @click="OpenConfirmDeletePricelist(tr.id,tr.bez)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>
                      <vs-button  @click="CopyPricelist(tr)" class="ml-2" size="small" color="primary" type="filled" icon="file_copy"></vs-button>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
        <vs-card v-if="showMember">
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddPricelistMember">{{$t('PRICELISTS.MEMBER.ADD')}}</vs-button>
            </div> 
            <div id="pricelists_member_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="pricelistMember"
                :noDataText="$t('PRICELISTS.MEMBER.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PRICELISTS.MEMBER.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('PRICELISTS.MEMBER.TABLE.COL1')}}
                  </vs-th>                 
                  <vs-th sort-key="mwst_fk">
                    {{$t('PRICELISTS.MEMBER.TABLE.COL2')}}
                  </vs-th>                   
                  <vs-th sort-key="netto">
                    {{$t('PRICELISTS.MEMBER.TABLE.COL3')}}
                  </vs-th>                                               
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.product_fk">
                      {{tr.use_text == true ? tr.text : GetBezOfProduct(tr.product_fk)}}
                      <vs-icon v-show="tr.use_netto_from" icon="link" size="small"></vs-icon>
                    </vs-td>                         
                    <vs-td :data="tr.product_fk">
                      {{GetBezOfMwst(tr.mwst_fk)}}
                    </vs-td> 
                    <vs-td :data="tr.netto">
                      {{GetDecimalStringInPricelist(tr.pricelist_fk, tr.netto) }}
                    </vs-td>                                 
                    <vs-td>
                      <vs-button  @click="EditPricelistMember(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>
                      <vs-button  @click="OpenConfirmDeletePricelistMember(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>   
            <AddPricelistMemberDlg ref="addPricelistMemberDlg" v-on:Success="PricelistMemberSaved"></AddPricelistMemberDlg>        
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import {documentType} from "../helper/enumHelper";
import enumHelper from '../helper/enumHelper';
import AddPricelistDlg from './components/dialogs/AddPricelistDlg.vue';
import AddPricelistMemberDlg from './components/dialogs/AddPricelistMemberDlg.vue';

export default {
  name: "Pricelists",
  components: {
    AddPricelistDlg,
    AddPricelistMemberDlg
  },
    data: function (){
      return {
      pricelists:[],
      pricelistMember:[],
      selectedPricelist:[],
      selectedMember:[],
      selectedMemberOld:"",
      newPricelist:false,
      newPricelistMember:false,
      showMember:false
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.pricelists.pricelists.data != null)
      {
        data = this.$store.state.pricelists.pricelists.data;
      }
      return data;
    },
    getMemberData(){
      var data = [];
      if(this.$store.state.pricelists.pricelistMember.data != null)
      {
        data = this.$store.state.pricelists.pricelistMember.data
      }
      return data;
    },
    pricelistBlocks(){
      return this.GetListOfPricelistBlocksForSelect();
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.pricelists.pricelists.status) != 'undefined')
        loading = this.$store.state.pricelists.pricelists.status.loading;
      return loading;
    },
    memberDataChanged(){
        return JSON.stringify(this.selectedMember) != this.selectedMemberOld;
    },
    saveAllowedMember(){
      return this.CheckInputMember() && !this.memberDataChanged;
    },
    countries(){
      return this.GetListOfCountriesForSelect();
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect();
    },
    products(){
      return this.GetListOfProductsForSelect();
    },
    mwst(){
      return this.GetListOfMwstForSelect(this.selectedPricelist.country_fk);
    }
  },
  watch: {
      getTableData(value) {
          this.pricelists = value;
      },
      getMemberData(value)
      {
        this.pricelistMember = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#pricelists_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#pricelists_table'});
        }
      },
      selectedPricelist(value)
      {
        if(typeof(value.id)!= 'undefined')
        {
          if(value.id > 0)
          {
            this.LoadPricelistMember(value.id);
            this.showMember = true;
          }
          else
          {
            this.showMember = false;
          }
        }
      }
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      LoadData: function ()
      {
          this.$store.dispatch('pricelists/getPricelists'); 
          this.showMember = false;
      },
      LoadPricelistMember(pricelist_fk)
      {
        this.$store.dispatch('pricelists/getPricelistMember',pricelist_fk); 
      },
      GetDecimalStringInPricelist(pricelist_fk, value)
      {
        var retVal = "";
        if(this.$store.state.pricelists.pricelists.data != null)
        {
          for(var i = 0; i < this.$store.state.pricelists.pricelists.data.length; i++)
          {
            var pr = this.$store.state.pricelists.pricelists.data[i];
            if(pr.id==pricelist_fk)
            {
              retVal = this.GetCurrencyString(pr.currency_fk,value);
              break;
            }
          }
        }
          
        return retVal;
      }, 
      PrintPricelist(id){
            var name = this.GetBezOfPriceList(id);
            this.$store.dispatch('document/createDocument', {id:id, type: documentType.PRICELIST})
              .then(success => {
                if(success === true)
                {
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRICELISTS.SUCCESS.PRINT').replace("*1*",name)});
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICELISTS.ERROR.PRINT').replace("*1*",name)});
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICELISTS.ERROR.PRINT').replace("*1*",name)+' '+error});
              }); 
      },
      EditPricelist(id){
      
        if(this.$store.state.pricelists.pricelists.data != null)
        {
          for(var i = 0; i < this.$store.state.pricelists.pricelists.data.length; i++)
          {
            if(this.$store.state.pricelists.pricelists.data[i].id==id)
            {
              var tmp = JSON.stringify(this.$store.state.pricelists.pricelists.data[i]);
              this.$refs.addPricelistDlg.InitDlgData(JSON.parse(tmp));
              break;
            }
          }
        }
      },
      CopyPricelist(data){
        this.$refs.addPricelistDlg.InitDlgDataForCopy(JSON.parse(JSON.stringify(data)));
      },
      GetBezOfPriceList(id){
        
        var retVal = "";
        if(this.$store.state.pricelists.pricelists.data != null)
        {
          for(var i = 0; i < this.$store.state.pricelists.pricelists.data.length; i++)
          {
            if(this.$store.state.pricelists.pricelists.data[i].id==id)
            {
              retVal = this.$store.state.pricelists.pricelists.data[i].bez;
              break;
            }
          }
        }
        return retVal;
      },      
      EditPricelistMember(data){
        this.$refs.addPricelistMemberDlg.InitDlg(data,this.selectedPricelist.country_fk);
      },  
      PricelistMemberSaved(pricefk){
        this.LoadPricelistMember(pricefk);
      } ,   
      AddPricelist(){
        this.$refs.addPricelistDlg.ShowDlg();
      },
      AddPricelistMember(){
        this.selectedMember={id:0,pricelist_fk:this.selectedPricelist.id,product_fk:-1,mwst_fk:-1,netto_from_fk:-1,netto:0.0,
                              use_netto_from:false,use_text:false,text:"",zusatz_info:0,sortOrder:0,variantMember:[]};
        this.selectedMemberOld = JSON.stringify(this.selectedMember);
        this.$refs.addPricelistMemberDlg.ShowDlg(this.selectedMember,this.selectedPricelist.country_fk);
      },
      CheckInput(){
        return true;
      },
      CheckInputMember(){
        
        // Wenn man in das numeric dings händisch eintippt kommt es als text an... umwandeln!
        if(typeof(this.selectedMember.netto) != 'undefined')
        {
          this.selectedMember.netto = Number(this.selectedMember.netto);
        }
        if(typeof(this.selectedMember.zusatz_info) != 'undefined')
        {
          this.selectedMember.zusatz_info = Number(this.selectedMember.zusatz_info);
        }
        return true;
      },      
      OpenConfirmDeletePricelist(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PRICELISTS.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('pricelists/deletePricelist', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRICELISTS.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
              var text = this.$t('PRICELISTS.WARNING.DELETE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text});
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICELISTS.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      OpenConfirmDeletePricelistMember(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('PRICELISTS.MEMBER.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteMemberRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteMemberRequest: function(parameters)
      {
        this.$store.dispatch('pricelists/deletePricelistMember', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRICELISTS.MEMBER.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
              var text = this.$t('PRICELISTS.MEMBER.WARNING.DELETE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text});
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICELISTS.MEMBER.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>