<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddPricelist"
      :is-valid="formValid">
       <div>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('PRICELISTS.EDIT.LABEL.BEZ')"  class="inputx" :placeholder="$t('PRICELISTS.EDIT.PREV.BEZ')"  v-model="pricelist.bez" />
            <vs-input :label="$t('PRICELISTS.EDIT.LABEL.NOTE')"  class="inputx ml-3" :placeholder="$t('PRICELISTS.EDIT.PREV.NOTE')"  v-model="pricelist.note" />
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-select :label="$t('PRICELISTS.EDIT.LABEL.WAEHRUNG')" v-model="pricelist.currency_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencies" />
            </vs-select>
            <vs-select :label="$t('PRICELISTS.EDIT.LABEL.LAND')" class="ml-3 " v-model="pricelist.country_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <DatePicker Type="date" class="" :Label="$t('PRICELISTS.LABEL.DATE_FROM')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('PRICELISTS.TITLE.DATE_FROM')" v-model="pricelist.valid_from"></DatePicker>
            <DatePicker Type="date" class="" :Label="$t('PRICELISTS.LABEL.DATE_TO')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('PRICELISTS.TITLE.DATE_TO')" v-model="pricelist.valid_to"></DatePicker>
          </div>
          <div class="default-input d-flex align-items-center mt-2">
            <vs-checkbox v-show="isCopy" v-model="link">{{$t('PRICELISTS.EDIT.LABEL.LINK')}}</vs-checkbox> 
          </div>
        </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import DatePicker from '../DatePicker';

export default {
  name: "AddPricelistDlg",
  props:[],
  components:{
    DatePicker
  },
  data: function (){
     return {
       pricelist:{id:0,bez:"",currency_fk:-1,country_fk:-1,company_fk:-1,note:"",valid_from:null,valid_to:null},
       dlgActive:false,
       popupTitle:this.$t('PRICELISTS.NEW.HEADER'),
       country_fk_fail:false,
       country_fk_ok:false,
       currency_fk_fail:false,
       currency_fk_ok:false,
       bez_fail:false,
       bez_ok:false,
       isCopy:false,
       link:false
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.pricelist = {id:0,bez:"",currency_fk:-1,country_fk:-1,company_fk:-1,note:"",valid_from:null,valid_to:null};
        if(this.IsNotNull(this.currencies))
        {
          if(this.currencies.length > 1)
          {
            this.currency_fk = this.currencies[1].value;
          }
        }

        if(this.IsNotNull(this.countries))
        {
          if(this.countries.length > 1)
          {
            this.country_fk = this.countries[1].value;
          }
        }

        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){

        this.ClearForm();
        if(this.IsNotNull(data))
        {
          this.popupTitle = this.$t('PRICELISTS.EDIT.HEADER');
          this.pricelist = data;

          this.dlgActive=true;
        }
      },
      InitDlgDataForCopy(data)
      {
        this.ClearForm();
        if(this.IsNotNull(data))
        {
          this.popupTitle = this.$t('PRICELISTS.COPY.HEADER');
          this.pricelist = data;
          this.pricelist.bez += ' COPY';
          this.isCopy = true;
          this.dlgActive=true;
        }
        
      },
      accepted(){
      
       var name = this.pricelist.bez;
        var mod = 'pricelists/savePricelist';
        var data = this.pricelist;
        if(this.isCopy == true)
        {
          mod = 'pricelists/copyPricelist';
          data = {link:this.link,pricelist:this.pricelist};
        }

          this.$store.dispatch(mod, data)
          .then(response => {
            if(response.success == true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRICELISTS.SUCCESS.SAVE').replace("*1*",name)});
              this.$emit('Success');
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICELISTS.ERROR.SAVE').replace("*1*",name)});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICELISTS.ERROR.SAVE').replace("*1*",name)+' '+error});
          }); 
        
        this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
          this.pricelist.id = 0;
          this.pricelist.country_fk = -1;
          this.country_fk_fail = false;
          this.country_fk_ok = false;
          this.currency_fk_fail = false;
          this.currency_fk_ok = false;
          this.pricelist.currency_fk = -1;
          this.pricelist.bez = "";
          this.bez_fail = false;
          this.bez_ok = false;
          this.pricelist.note = "";
          this.pricelist.valid_from = null;
          this.pricelist.valid_to = null;
          this.isCopy = false;
          this.link = false;
      }
  },
  computed:{
    formValid(){
      return this.bez_ok && this.country_fk_ok && this.currency_fk_ok;
    },
    countries(){
      return this.GetListOfCountriesForSelect(false);
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect(false);
    },
    country_fk(){
      return this.pricelist.country_fk;
    },
    currency_fk(){
      return this.pricelist.currency_fk;
    },
    bez(){
      return this.pricelist.bez;
    }
  },
  watch:{
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    country_fk(value){
      this.country_fk_fail = value <= 0;
      this.country_fk_ok = !this.country_fk_fail;
    },
    currency_fk(value){
      this.currency_fk_fail = value <= 0;
      this.currency_fk_ok = !this.currency_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddPricelist > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddPricelist > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>