<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.OK')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddShipment"
      :is-valid="formValid">
      <div>
        <vs-tabs>
          <vs-tab :label="$t('PRICELISTS.MEMBER.DATA')" >
            <div class="default-input d-flex align-items-center mt-3">
              <vs-select :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.PRODUCT')" v-model="fulldata.product_fk">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in products" />
              </vs-select>
              <vs-select :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.MWST')" class="ml-3 " v-model="fulldata.mwst_fk">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in mwstForSelect" />
              </vs-select>         
            </div>
            <div class="default-input d-flex align-items-center mt-2">
              <vs-input-number v-model="fulldata.netto" :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.NETTO')" class="inputx" />
              <vs-input-number v-model="fulldata.sortOrder" :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.SORT')" class="inputx ml-3" />
            </div>  
            <vs-divider position="center" >{{$t('PRICELISTS.MEMBER.EDIT.LABEL.CALCNETTOFROMBRUTTO')}}</vs-divider>
            <div class="default-input d-flex align-items-center mt-2">
              <vs-input-number v-model="brutto" :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.BRUTTO')" class="inputx" />
              <vs-button @click="CalcNewNettoPrice" size="small" class="ml-2" icon="functions">{{$t('PRICELISTS.MEMBER.EDIT.BTN.CALC')}}</vs-button>
            </div>            
            <vs-divider position="center" >{{$t('PRICELISTS.MEMBER.EDIT.LABEL.TEXTOPTIONS')}}</vs-divider>
            <div class="default-input d-flex align-items-center mt-2">
              <vs-input :disabled="!fulldata.use_text" :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.TEXT')"  class="inputx float-left" :placeholder="$t('PRICELISTS.MEMBER.EDIT.PREV.TEXT')"  v-model="fulldata.text" />  
            </div>
            <div class="default-input d-flex align-items-center mt-2">
              <vs-checkbox v-model="fulldata.use_text"  class="inputx float-left" >{{$t('PRICELISTS.MEMBER.EDIT.LABEL.USE_TEXT')}}</vs-checkbox>
            </div>
            <vs-divider position="center" >{{$t('PRICELISTS.MEMBER.EDIT.LABEL.OPTIONS')}}</vs-divider>
            <vs-select :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.ZUSATZ')" class="" v-model="fulldata.zusatz_info">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in pricelistMemberZusatzinfoTypes" />
            </vs-select> 
            
          </vs-tab>
          <vs-tab :label="$t('PRICELISTS.MEMBER.VARIANTS')" >
            <div class="d-flex align-items-center">
              <vs-select  class="" v-model="vardata.productvariant_fk">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in prodvariants" />
              </vs-select> 
              <vs-input-number v-model="vardata.netto" :label="$t('PRICELISTS.MEMBER.VAR.NETTO')" class="inputx ml-3" />
              <vs-input-number v-model="vardata.order" :label="$t('PRICELISTS.MEMBER.VAR.ORDER')" class="inputx ml-3" />
              <vs-button class="ml-3"  type="filled" icon="add" @click="AddVariantMember" v-show="!varEditMode" :disabled="!variantAddPossible"></vs-button>
              <vs-button class="ml-3"  type="filled" icon="save" v-show="varEditMode" @click="SaveVariantMember"></vs-button>
              <vs-button class="ml-1"  type="filled" icon="clear" v-show="varEditMode" @click="ClearVariant"></vs-button>
            </div> 
            <div class="d-flex align-items-center mb-3 mt-2">
              <vs-checkbox class="" v-model="vardata.active">{{$t('PRICELISTS.MEMBER.VAR.ACTIVE')}}</vs-checkbox>
              <vs-checkbox class="ml-3" v-model="vardata.isDefault">{{$t('PRICELISTS.MEMBER.VAR.DEFAULT')}}</vs-checkbox>
              <vs-checkbox class="ml-3" v-model="vardata.print">{{$t('PRICELISTS.MEMBER.VAR.PRINT')}}</vs-checkbox>
              <vs-input v-model="vardata.extratext_pricelist_print" :label="$t('PRICELISTS.MEMBER.VAR.EXTRA')" class="inputx ml-3" />
              <ProductPicker v-if="IsProductPickerActive(vardata.productvariant_fk)" label="productLabel" :isLabelOkay="false" :pricesLastYear="false" :country_fk="country_fk" :currency_fk="1" v-model="vardata.product_fk" :withMotifpickerOnly="false"></ProductPicker>

            </div>
            <vs-divider position="center"  v-show="varEditMode" >{{$t('PRICELISTS.MEMBER.EDIT.LABEL.CALCNETTOFROMBRUTTO')}}</vs-divider>
            <div class="default-input d-flex align-items-center mt-2"  v-show="varEditMode">
              <vs-input-number v-model="bruttoVar" :label="$t('PRICELISTS.MEMBER.EDIT.LABEL.BRUTTO')" class="inputx"  v-show="varEditMode" />
              <vs-button @click="CalcNewNettoPriceVariant" size="small" class="ml-2" icon="functions"  v-show="varEditMode">{{$t('PRICELISTS.MEMBER.EDIT.BTN.CALC')}}</vs-button>
            </div>
            <div id="variant_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="fulldata.variantMember"
                :noDataText="$t('PRICELISTS.MEMBER.VAR.NO_DATA')"
                stripe
                max-items="5" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="productvariant_fk">
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="netto">
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="active">
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="isDefault">
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.COL4')}}
                  </vs-th> 
                  <vs-th sort-key="print">
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.COL6')}}
                  </vs-th> 
                  <vs-th sort-key="order">
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.COL5')}}
                  </vs-th> 
                  <vs-th sort-key="extratext_pricelist_print">
                    {{$t('PRICELISTS.MEMBER.VAR.TABLE.COL6')}}
                  </vs-th>                                                                     
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="'grp_'+indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.productvariant_fk">
                      {{GetVariantBez(tr.productvariant_fk)}}
                    </vs-td>      
                    <vs-td :data="tr.netto">
                      {{tr.netto.toFixed(2)}}
                    </vs-td> 
                    <vs-td :data="tr.active">
                      <vs-checkbox :disabled="true" v-model="tr.active"></vs-checkbox>
                    </vs-td>
                    <vs-td :data="tr.active">
                      <vs-checkbox :disabled="true" v-model="tr.isDefault"></vs-checkbox>
                    </vs-td>  
                    <vs-td :data="tr.print">
                      <vs-checkbox :disabled="true" v-model="tr.print"></vs-checkbox>
                    </vs-td> 
                    <vs-td :data="tr.order">
                      {{tr.order}}
                    </vs-td> 
                    <vs-td :data="tr.extratext_pricelist_print">
                      {{tr.extratext_pricelist_print}}
                    </vs-td>                                                           
                    <vs-td>
                      <div class="d-flex">
                        <vs-button  @click="EditVariantMember(tr)" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                        <vs-button  @click="RemoveVariantMember(tr.id)" class="ml-1" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                      </div>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import ProductPicker from '../ProductPicker.vue';
import {productVariantExtraOptions} from '../../../helper/enumHelper';

export default {
  name: "AddPricelistMemberDlg",
  props:['Type'],
  components:{
    ProductPicker
    },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('PRICELISTS.MEMBER.NEW.HEADER'),
      fulldata:null,
      country_fk:0,
      vardata:{},
      varEditMode:false,
      brutto:0,
      bruttoVar:0
    }
  }, 
  mounted () {
    this.$store.dispatch('productvariant/getProductvariants'); 
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      CalcNewNettoPrice(){
        this.fulldata.netto = this.brutto / ((this.mwstProz + 100) / 100);
      },
      CalcNewNettoPriceVariant(){
        this.vardata.netto = this.bruttoVar / ((this.mwstProz + 100) / 100);
      },
      ShowDlg(data,country_fk){
        this.ClearForm();
        this.fulldata = data;
        this.country_fk = country_fk;
        this.dlgActive=true;
      },
      InitDlg(data,country_fk)
      {
        this.ClearForm();
        this.fulldata = data;
        this.popupTitle = this.$t('PRICELISTS.MEMBER.EDIT.HEADER');
        this.country_fk = country_fk;

        this.brutto = (this.fulldata.netto * (100 + this.mwstProz)/100).toFixed(2);

        this.dlgActive=true;
        
      },
      closed(){
        this.ClearForm();
      },
      accepted(){

        var name = this.GetBezOfProduct(this.fulldata.product_fk);
        var pricefk = this.fulldata.pricelist_fk;
        this.fulldata.netto = Number(this.fulldata.netto);
        this.fulldata.sortOrder = Number(this.fulldata.sortOrder);
        this.fulldata.zusatz_info = Number(this.fulldata.zusatz_info);
        this.fulldata.netto_from_fk = Number(this.fulldata.netto_from_fk);
        this.fulldata.netto_from_fk = Number(this.fulldata.netto_from_fk);
        this.$store.dispatch('pricelists/savePricelistMember', this.fulldata)
          .then(response => {
            if(response.success == true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PRICELISTS.MEMBER.SUCCESS.SAVE').replace("*1*",name)});
              this.$emit('Success',pricefk);
            }
            else
            {
              var text = this.$t('PRICELISTS.MEMBER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PRICELISTS.MEMBER.ERROR.SAVE').replace("*1*",name)+' '+error});
          });  

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.popupTitle = this.$t('PRICELISTS.MEMBER.NEW.HEADER');
        this.brutto = 0;
        this.bruttoVar = 0;
        this.ClearVariant();
      },
      ClearVariant(){
        this.vardata = {id:0,productvariant_fk:-1, netto:0, active:true,pricelistmember_fk:0,isDefault:false,order:0,print:false};
        this.varEditMode = false;
      },
      AddVariantMember(){
          this.vardata.pricelistmember_fk = this.fulldata.id;
          this.fulldata.variantMember.push(this.vardata);
          this.ClearVariant();
      },
      EditVariantMember(data){
        this.vardata.id = data.id;
        this.vardata.productvariant_fk = data.productvariant_fk;
        this.vardata.netto = data.netto;
        this.vardata.active = data.active;
        this.vardata.pricelistmember_fk = data.pricelistmember_fk;
        this.vardata.order = data.order;
        this.vardata.isDefault = data.isDefault;
        this.vardata.print = data.print;
        this.vardata.product_fk = data.product_fk;
        this.vardata.extratext_pricelist_print = data.extratext_pricelist_print;

        this.bruttoVar = (data.netto * (100 + this.mwstProz)/100).toFixed(2);

        this.varEditMode = true;
      },
      SaveVariantMember(){
        for(var i = 0; i < this.fulldata.variantMember.length; i++)
        {
          if(this.vardata.id == this.fulldata.variantMember[i].id)
          {
            this.fulldata.variantMember[i].active = this.vardata.active;
            this.fulldata.variantMember[i].netto = this. vardata.netto;
            this.fulldata.variantMember[i].productvariant_fk = this.vardata.productvariant_fk;
            this.fulldata.variantMember[i].order = this.vardata.order;
            this.fulldata.variantMember[i].isDefault = this.vardata.isDefault;
            this.fulldata.variantMember[i].print = this.vardata.print;
            this.fulldata.variantMember[i].product_fk = this.vardata.product_fk;
            this.fulldata.variantMember[i].extratext_pricelist_print = this.vardata.extratext_pricelist_print;
            this.ClearVariant();
            break;
          }
        }
        
      },
      RemoveVariantMember(id){
        for(var i = 0; i < this.fulldata.variantMember.length; i++)
        {
          if(this.fulldata.variantMember[i].id == id)
          {
            this.fulldata.variantMember.splice(i, 1);
            break;
          }
        }
      },
      GetVariantBez(id){

        var ret = "";

        var variant = this.GetProductVariant(id);
        if(this.IsNotNull(variant))
        {
           ret = variant.bez + " ("+variant.label_short+")";
        }
        return ret;
      },
      IsProductPickerActive(id)
      {
        var ret = false;
        
        var variant = this.GetProductVariant(id);
        if(this.IsNotNull(variant))
        {
           ret = (variant.extraoption == productVariantExtraOptions.PRODUCTPICKER || variant.extraoption == productVariantExtraOptions.MOTIFPICKER);
        }
        return ret;
      }

  },
  computed:{
    formValid(){
      return true;
    },
    products(){
      return this.GetListOfProductsForSelect();
    },
    mwstForSelect(){
      return this.GetListOfMwstForSelect(this.country_fk);
    },
    mwstProz(){
      var ret = 0;
      var mwst = this.GetMwStById(this.fulldata.mwst_fk);
      if(mwst != null)
      {
        ret = Number(mwst.value);
      }
      return ret;
    },
    prodvariants(){
      return this.GetListOfProductVariantsForSelect();
    },
    variantAddPossible(){
      return this.vardata.productvariant_fk > 0 ;
    },
    pricelistMemberZusatzinfoTypes(){
      return this.GetPricelistMemberZusatzinfoTypeForSelect();
    },

  },
  watch:{
  }
};
</script>
<style>
div#promptAddShipment > div.vs-dialog{
  max-width: 800px !important;
}
</style>